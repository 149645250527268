export default {
    data: function () {
        return {
            orderId:'',
            order_sn:'',
            pay_amonut:0,
            openBillShow:false,
            fptext:[
                {
                    value: 1,
                    label: '增值税普通发票'
                }, {
                    value: 2,
                    label: '增值税专用发票'
                }
            ],
            fpType:1,
            ristype: 1,
            fpname:'',
            disnum:'',
            fpbank:'',
            fpaccount:'',
            fpaddress:'',
            fpmobile:'',
            spusername:'',
            spmobile:'',
            spaddress:'',
            is_default:1,
            disabledOff:false,
            sub:0
        }
    },
    watch: {
        $route: {
            handler: function(val, oldVal){
                if(val.query && !val.query.sub) {
                    this.sub = 0
                }
            }
        }
    },
    created() {
        let query = this.$route.query;
        this.sub = query.sub?query.sub:0
    },
    methods: {
        toDetail:function(order_id){
            this.$router.push({ path: "/order-detail", query: { id: order_id,sub:this.sub} });
            // this.$router.push({name:'OrderDetail',params:{id:order_id}})
        },
        changeDrawing: function (order_id) {
           this.$router.push({name:'Order-online',params:{id:order_id}}) 
        },
        cancelOrder: function (order_id) {
            this.$confirm('是否取消该订单?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$post('/api/order/cancelOrder.html', {
                    token: true,
                    order_id: order_id
                }).then((res) => {
                    console.log(res)
                    this.orderLists = []
                    if (res.code == 200) {
                        this.getOrderLists(this.state,this.fid);
                    } else {
                       
                    }
                    return this.$message(res.msg);
                }).finally(() => {

                })
            }).catch(() => {

            });

        },
        refuseOrder: function (order_id) {
            this.$confirm('是否拒绝报价?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$post('/api/order/refuseOrder.html', {
                    token: true,
                    order_id: order_id
                }).then((res) => {
                    console.log(res)
                    this.orderLists = []
                    if (res.code == 200) {
                        this.getOrderLists(this.state,this.fid);
                    } else {

                    }
                    return this.$message(res.msg);
                }).finally(() => {

                })
            }).catch(() => {

            });

        },
        downloadOffer: function (id) {
            this.$post('/api/order/downloadOffer.html', {
                order_id: id,
                token: true
            }).then((res) => {
                console.log(res)
                if (res.code == 200) {

                    var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
                    var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器
                    if(isIE){
                        var gotolink = document.createElement('a');
                        gotolink.href = res.data;
                        gotolink.setAttribute("target", "_blank");
                        document.body.appendChild(gotolink);
                        gotolink.click();
                    }else{
                        window.open(res.data)
                    }
                } else {
                    return this.$message(res.msg);
                }
            }).finally(() => {

            })
        },
        openBill:function (e) {
            console.log(e);
            this.disabledOff = false;
            this.orderId = e.id;
            this.order_sn = e.order_sn;
            this.pay_amonut = e.pay_amonut;
            if(!this.orderId || !this.order_sn){
                this.$message.warning('此订单有误');
                return;
            }
            this.openBillShow = true;
            this.$post('/api/order/applyDefault.html', {token:true}).then((res) => {
                console.log(res);
                if (res.code == 200) {
                    if(res.data){
                            this.fptype=res.data.fptype
                            this.ristype=res.data.ristype
                            this.fpname=res.data.fpname
                            this.disnum=res.data.disnum
                            this.fpbank=res.data.fpbank
                            this.fpaccount=res.data.fpaccount
                            this.fpaddress=res.data.fpaddress
                            this.fpmobile=res.data.fpmobile
                            this.spusername=res.data.spusername
                            this.spmobile=res.data.spmobile
                            this.spaddress=res.data.spaddress
                            this.is_default=res.data.is_default
                    }
                }else{
                    this.disabledOff = false;
                    return this.$message(res.msg);
                }
            })
        },
        applyFp:function () {
            if(!this.fpname){
                this.$message('开票名称不能为空');return;
            }
            if(this.ristype == 2){
                if(!this.disnum){
                    this.$message('纳税人识别号不能为空');return;
                }
            }
            if(!this.spusername || !this.spmobile || !this.spaddress){
                this.$message('收票地址不完善');return;
            }
            let formData = {
                token:true,
                order_id:this.orderId,
                order_sn:this.order_sn,
                pay_amount:this.pay_amonut,
                fptype:this.fptype,
                ristype:this.ristype,
                fpname:this.fpname,
                disnum:this.disnum,
                fpbank:this.fpbank,
                fpaccount:this.fpaccount,
                fpaddress:this.fpaddress,
                fpmobile:this.fpmobile,
                spusername:this.spusername,
                spmobile:this.spmobile,
                spaddress:this.spaddress,
                is_default:this.is_default,
            }
            this.disabledOff = true;
            this.$post('/api/order/applyFp.html', formData).then((res) => {
                if (res.code == 200) {
                    this.$message(res.msg);
                    this.openBillShow = false;
                    this.getOrderLists(this.state,this.fid);
                }else{
                    this.disabledOff = false;
                    return this.$message(res.msg);
                }
            }).finally(() => {

            })
        },
        noapplyFp:function () {
            this.$post('/api/order/noapply.html', {order_id:this.orderId}).then((res) => {
                console.log(res);
                if (res.code == 200) {
                   this.$message(res.msg);
                   this.openBillShow = false;
                   this.getOrderLists(this.state,this.fid);
                }else{
                    this.disabledOff = false;
                    return this.$message(res.msg);
                }
            })
        }
    },
}