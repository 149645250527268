<template>
  <div class="order-online">
    <div class="h6">在线下单</div>
    <div class="content">
      <div class="classliy cf">
        <div class="fl sel-box cf">
          <span class="label fl">加工种类</span>
          <el-select class="select fl" v-model="value" placeholder="请选择">
            <el-option
              v-for="item in categoryList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <!-- <div class="fl inp-box cf">
          <span class="label fl">姓名</span>
          <input type="text" v-model="contact_user" name="" id="" placeholder="请输入姓名" />
        </div>
        <div class="fr inp-box cf">
          <span class="label fl">手机号</span>
          <input type="text" v-model="phone_num" name="" id="" placeholder="请输入手机号" />
        </div> -->
      </div>
      <div class="up-header cf">
        <div class="fl l-box">
          <span class="fl label">是否需要签署保密协议</span>
          <el-radio class="fl" v-model="xy" label="1">不签署</el-radio>
          <el-radio class="fl" v-model="xy" label="2">签署</el-radio>
        </div>
        <div class="fr r-box">
          <div class="up-btn fl" @click="checkedAll">
            <img src="../../../../../assets/images/tzsc_iocn_xz2.png" alt="" />
            <span>全选</span>
          </div>
          <!-- <div class="up-btn fl">
            <img src="../../../../../assets/images/tzsc_iocn_xz.png" alt="" />
            <span>下载</span>
          </div> -->
          <div class="up-btn delete fl" @click="deleteTz">
            <img
              src="../../../../../assets/images/tzsc_iocn_delete.png"
              alt=""
            />
            <span>删除</span>
          </div>
        </div>
      </div>
      <div class="up-box"  v-loading="loading">
<!--        <el-upload-->
<!--          class="upload-demo"-->
<!--          drag-->
<!--          :on-progress="progress"-->
<!--          :data="upData"-->
<!--          :action="action"-->
<!--          :before-upload='begin'-->
<!--          v-loading="loading"-->
<!--          :on-success="upWj"-->
<!--          :show-file-list="false"-->
<!--        >-->

        <el-upload
                class="upload-demo"
                drag
                :data="upData"
                action=""
                :http-request="begin"
                :show-file-list="false"
                :on-change="handleFileChange"
                :on-progress="progress"
        >

          <div class="f-box">
            <img src="../../../../../assets/images/tzsc_iocn_sc.png" alt="" />
            <span>点击或拖拽文件上传</span>
          </div>
          <div class="s-box">
            <p>
              支持3D格式: STEP(.step, .stp), Solidworks(.sldprt)， Pro/E(.prt),
              Inventor(.ipt), CATIA(.CATPart), ACIS(.x _t),
            </p>
            <p>
              支持2D格式: dwg, dxf, pdf,可压缩包(zip、 rar)
              直接上传，建议您同时上传3D和2D图纸
            </p>
            <p>同-款零件的3D和2D图纸的名称要一致</p>
            <p>单个文件大小<64M</p>
          </div>
        </el-upload>
      </div>
      <el-progress
        style="margin-top:10px"
        v-if="loading"
        :text-inside="true"
        :stroke-width="26"
        :percentage="percentage"
      ></el-progress>
      <div class="table">
        <ul class="t-header cf">
          <li class="item fl" v-for="item in tHeader" :key="item.id">
            {{ item.text }}
          </li>
        </ul>
        <ul class="cf t-item" v-for="(item, index) in productList" :key="index">
          <li class="item fl" @click="checkedItem(index)">
            <img v-if="item.checked == 1" src="../../../../../assets/images/xk_iocn_xz.png" alt="">
            <div v-else class="check-box"></div>
          </li>
          <li class="item fl">{{ index+1 }}</li>
          <li class="item fl" style="cursor: pointer;">
            <img :src="item.everbase?item.everbase:tzList[index]"  @click="everCraft(item.everurl, item.everfilename)" style="width: 71px;height: 41px;margin-top: 5px;">
          </li>
          <li class="item fl" style="cursor: pointer;" @click="everCraft(tzList[index], item.completely_name)" >
            {{item.completely_name}}
          </li>
          <li class="item fl">
            <input type="text" :data-oldname='item.img_name' data-key='img_name'  @input="change" :value="item.img_name" name="" >
 
          </li>
          <li class="item fl">{{item.file_format}}</li>
          <li class="item fl">{{item.img_name}}</li>
          <li class="item fl">
            <input type="text" :data-oldname='item.img_name' data-key='version_num'  @input="change" :value="item.version_num">
          </li>
          <li class="item fl">{{item.file_size}}</li>
          <li class="item fl">
            <input type="text"  :data-oldname='item.img_name' data-key='order_nums' @input="change" :value="item.order_nums" name=""/>
          </li>
          <li class="item fl">
            <input type="text" v-model="item.file_content">
          </li>
        </ul>
      </div>
      <div class="tj-btn" @click="saveOrder">
        获取报价
      </div>
    </div>
    <div class="alert-box" v-if="show">
      <!-- <div class="cf">
        <div class="fr close"  @click="close">X</div>
      </div> -->
      <!-- <h5>{{clause.content[index].title}}</h5> -->
      <div class="context" v-html="content"></div>
      <div class="close-btn" @click="close">阅读并同意该协议</div>
    </div>

    <div style="width: 0px;position:fixed;z-index: -999;">
      <div id="everroot" style="width: 0px; height: 0px;"></div>
      <img id = "everpic" :src="everbase" loading="lazy"  width="100" height="50">
    </div>

    <div class="open-img" v-if="imgShow">
      <div class="cf">
        <span class="fr" @click="imgclose">X</span>
      </div>
      <div id="root" style="width: 1000px; height: 500px; margin: 0 auto"></div>
    </div>

  </div>
</template>
<script>
import mixins from "../mixins";
export default {
  mixins,
};
</script>
<style scoped>
@import "../style.css";
</style>