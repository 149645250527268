export default {
    data: function () {
        return {
            categoryList: [{
                id: 1,
                name: '0'
            }],
            value: '',
        }
    },
    created() {
        this.getCategoryList();
    },
    methods: {
        getCategoryList: function () {
            this.$post('/api/order/categoryList.html', {}).then((res) => {
                console.log(res)
                if (res.code == 200) {
                    this.categoryList = res.data || []
                } else {
                    return this.$message(res.msg);
                }
            }).finally(() => {

            })
        }
    }
}