import handle from "../../order/mixins/handle";

export default {
    data() {
        return {

        }
    },
    created() {
        console.log()
        if (this.$route.params.id != undefined) {
            this.orderModifyImg(this.$route.params.id)
        }
    },
    methods: {
        orderModifyImg: function (id) {
            this.$post('/api/order/orderModifyImg.html', {
                token: true,
                order_id: id
            }).then((res) => {
                console.log(res)
                if (res.code == 200) {
                    this.value = res.data.category_id;
                    this.contact_user = res.data.customer;
                    this.phone_num = res.data.customer_phone;
                    let data = res.data.imgs_lists;
                    this.handleData(data)

                } else {
                    return this.$message(res.msg);
                }
            }).finally(() => {

            })
        },
        handleData: function (data) {
            let productList = [];
           
            for (let i = 0; i < data.length; i++) {  
                let obj = {
                    completely_name: "logo.png",
                    file_content: "备注信息",
                    file_format: "png",
                    file_size: "6kb",
                    img_id: "d7157c48e79479652134ea09d3c5ec7a",
                    img_name: "logo",
                    order_nums: 0,
                    version_num: "1.0",
                    checked: 0,
                    everfilename:'',
                    everurl:'',
                    everbase:''
                }
                obj.completely_name = data[i].completely_name || '';
                obj.file_content = data[i].file_content || '';
                obj.file_format = data[i].file_format || '';
                obj.file_size = data[i].file_size || '';
                obj.img_id = data[i].img_id || '';
                obj.img_name = data[i].img_name || '';
                obj.order_nums = data[i].order_nums || 1;
                obj.version_num = data[i].version_num || '';
                obj.checked = 1;
                obj.everfilename = data[i].everfilename || '';
                obj.everurl = data[i].everurl || '';
                obj.everbase = data[i].everbase || '';
                productList[i] = obj;
                console.log( data[i].order_nums );
            }
            console.log(productList)
            this.productList = productList;
        }
    }
}