import {everapi} from "../../../../../assets/js/everapi";
import axios from 'axios'
const config ={}
const api = new everapi('c4a0ab6479f5', '80761c29e3a30c1ad006fe8cf97f6884', config)
var everbase = '';
let apiLoaded = false
export default {
    data() {
        return {
            action: this.url + '/api/base/uploadImg.html',
            fileList: [],
            name: "upfile",
            headers: {
                // 'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq'
            },
            upData: {
                token: localStorage['yhtoken'],
            },
            xy: '1',
            productList: [
                // {
                //     completely_name: "logo.png",
                //     file_content: "备注信息",
                //     file_format: "png",
                //     file_size: "6kb",
                //     img_id: "d7157c48e79479652134ea09d3c5ec7a",
                //     img_name: "logo",
                //     order_nums: 0,
                //     version_num: "1.0",
                //     checked:0
                // },
                // {
                //     completely_name: "logo.jpg",
                //     file_content: "备注信息",
                //     file_format: "jpg",
                //     file_size: "6kb",
                //     img_id: "d7157c48e79479652134ea09d3c5ec7a",
                //     img_name: "logo",
                //     order_nums: 0,
                //     version_num: "1.0",
                //     checked:0
                // },{
                //     completely_name: "logo1231.jpg",
                //     file_content: "备注信息",
                //     file_format: "jpg",
                //     file_size: "6kb",
                //     img_id: "d7157c48e79479652134ea09d3c5ec7a",
                //     img_name: "logo1231",
                //     order_nums: 0,
                //     version_num: "1.0",
                //     checked:0
                // }
            ],
            loading:false,
            percentage:0,
            tzList:[],
            imgShow:false,
            fileStatusInterval:undefined,
            everfilename:'',
            everurl:'',
            everbase:'',
            uploadImgInterval:undefined,
            uploadAccept:['.stl','.obj','.3mf','.fbx','.gltf','.gcode','.stp','.step','.igs','.iges','.brep','.sldasm','.sldprt','.x_t','.x_b','.prt','.prt','.asm','.ipt','.iam','.3dxml','.cgr'],

        }
    },
    methods: {
        begin:function(file){
            this.loading = true;
            var getSuffix = function(fileName) {
                var pos = fileName.lastIndexOf(".");
                var suffix = '';
                if (pos != -1) {
                    suffix = fileName.substring(pos);
                }
                return suffix.toLowerCase();
            }
            if (file.length == 0) {
                this.$message.warning('未上传');
                return;
            }
            var uploadAccept = this.uploadAccept.join(',');
            if(uploadAccept.search(getSuffix(file.file.name)) == -1){
                this.uploadImg(file);
            }else{
                this.uploadFile1(file)
            }

        },
        progress:function(event, file, fileList){
            console.log(event, file, fileList)
            this.percentage = event.percent
        },
        async uploadFile1(file) {
        let parsing = false;
        let uploadProgress = 0;
        // 将选择的文件包装在 formData 中
        const formData = new FormData();
        formData.append("file", file.file);
        // 调用 uploadFile 方法，将包装好的 formData 上传到服务器
        let {filename, uuid} = await api.uploadFile(formData, (e) => {
            uploadProgress = e.loaded / e.total;
        });
        parsing = true;
        uploadProgress = 0;
        this.fileStatusInterval = setInterval(() => {
            this.pollingFileStatus(filename, uuid,file);
        }, 2000);
    },
    async pollingFileStatus(filename, uuid,file) {
        // 使用 uploadFile 返回的 uuid 查询该文件的转换状态
        const { status, url } = await api.getFileStatus(uuid);

        // 文件转换完成后，清除定时器
        if (status !== "pending" && status !== "running") {
            clearInterval(this.fileStatusInterval);
            this.everbase = '';
            //console.log(everbase);
            apiLoaded = false;
            this.uploadImgInterval = setInterval(() => {

                var base = document.getElementById('everpic').getAttribute('src');

                console.log(base)
                if(base){
                    this.everfilename = filename;
                    this.everurl = url;
                    this.everbase = base;
                    everbase = '';
                    this.uploadImg(file);
                }
            }, 2000);

        }

        // 文件转换成功后，加载到窗口中
        if (status === "succeed") {
            var currentFiles = [{ filename, url }];
            // api 加载成功后调用 loadApi 函数
            const config = {
                progress: {
                    useDefaultProgress: true,
                    onProgress: function(progress) {},
                    onRendered: function(volume, area, box) {
                        api.takePhoto('everroot', {
                            //includeMeasure: true, // 包含测量信息
                            onFinished: (base64) => {
                                everbase = '';
                                everbase = 'data:image/png;base64'+ base64;
                                document.getElementById('everpic').setAttribute( 'src', 'data:image/png;base64'+ base64);
                            }
                        })
                    },
                },
            }
            const api = new everapi('c4a0ab6479f5', '80761c29e3a30c1ad006fe8cf97f6884', config)
            window.addEventListener('oneverapiloaded', () => {
                // 此处放需要渲染的文件
                var e = document.querySelector("#everroot");
                e.innerHTML = "";
                const files = currentFiles
                currentFiles = '';
                loadApi(files)
            })
            //this.imgShow = true;
            function loadApi (files) {
                // 此方法的第一个参数为放置 dom 元素的 id，第二个参数为需要在 api 中显示的模型的数组
                api.render('everroot', files)
                apiLoaded = true
            }
        }
    },
    uploadImg:function(file){
            //this.uploadFile1(file.file)
            clearInterval(this.uploadImgInterval);
            const formD = new FormData();

            console.log(this.everbase);

            formD.append("file", file.file);
            formD.append("everfilename", this.everfilename);
            formD.append("everurl", this.everurl);
            formD.append("everbase", this.everbase);
            formD.append("token", localStorage['yhtoken']);
            const options = {
                baseURL: this.url,
                timeout: 60000,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {
                    let percent=(progressEvent.loaded / progressEvent.total * 100) | 0
                    //调用onProgress方法来显示进度条，需要传递个对象 percent为进度值
                    file.onProgress({percent:percent})
                }
            }
            const request = axios.create(options)
            request.post('/api/base/uploadImg.html', formD, config,)
                .then(res => {
                    file.onSuccess()
                    if (res.data.code == 200) {
                        document.getElementById('everpic').setAttribute( 'src', '');
                        this.loading = false;
                        this.percentage = 0;
                        this.everbase = '';
                        this.everfilename = ''
                        this.everurl = '';
                        everbase = '';
                        this.tzList.push(res.data.data.url)
                        this.getProductList(res.data.data.image_id)
                    } else {
                        this.loading = false;
                        this.percentage = 0;
                        this.everbase = '';
                        this.everfilename = ''
                        this.everurl = '';
                        everbase = '';
                        return this.$message('请上传符合规定格式和大小的文件');
                    }
                })

        },
        upWj: function (res) {
            console.log(res)
            this.loading = false;
            this.percentage = 0;
            if (res.code == 200) {
                this.tzList.push(res.data.url)
                this.getProductList(res.data.image_id)
            } else {
                return this.$message('请上传符合规定格式和大小的文件');
            }
        },
        getProductList: function (imgId) {
            let id = [];
            id.push(imgId)
            this.$post('/api/order/productList.html', {
                id: id

            }).then((res) => {
                console.log(res)
                if (res.code == 200) {
                    res.data[0].checked = 1;
                    this.productList = this.productList.concat(res.data)
                } else {
                    return this.$message(res.msg);
                }
            }).finally(() => {

            })
        },
        deleteTz:function(){
            let productList = this.productList;
            let deleArr = [];
            for(let i = 0;i<productList.length;i++){
        
                if(productList[i].checked == 1){
                    // 
                //  productList.splice(i,1)
                deleArr.push(i);
                }
            }
            deleArr.reverse();
            this.$confirm('删除选中的图纸?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                for(let i = 0;i<deleArr.length;i++){
                    productList.splice(deleArr[i],1)
                }
                this.productList = productList;
              }).catch(() => {
                        
              });
            // console.log(productList)
            // this.productList = productList;
        },
       
    }
}